.Card {
    box-shadow: 0 2px 6px 3px rgba(74, 74, 74, 0.2);
    height: 400px;
    width: 388px;
    padding: 0;

    & > :global(#dropdown-button) {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        margin-top: 14px;

        button {
            width: 100%;
        }
    }

    &:global(.create-custom-audience) {
        width: 568px;
    }
}

.AudienceTab {
    box-sizing: border-box;
    padding: 16px;
    overflow-y: auto;
    height: calc(100% - 102px);
    &:global(.noTabs) {
        height: 100%;
    }

    :global(.audience-placeholder) {
        width: 100%;
        height: 100%;
    }

    :global(.option) {
        height: 32px;
        display: flex;

        :global(.label) {
            padding: 0;
        }
    }

    :global(.branches) {
        :global(.branch) {
            margin-left: 10px;
        }
    }

    &:global(.advanced-tab) {
        :global(.adv-audience-empty-state) {
            span {
                text-align: center;
            }
        }

        :global(.link) {
            span {
                margin-left: 8px;
            }
        }
    }

    &:global(.simple-tab-style) {
        div[role=checkbox] {
            &:first-of-type {
                margin-left: 2px;
            }
        }

        >div {
            cursor: pointer;
        }

        span {
            padding-left: 8px;
        }

        :global(.select-all-checkbox) {
            margin-bottom: 16px;
        }
    }

    :global(.new-tree-select) {
        padding: 8px 0px;

        :global(.branch) :global(.branch-header) {
            padding: 0;
        }

        :global(.branch):global(.leaf) :global(.ladder) {
            height: 15px;
        }
    }

    .clearSelection {
        color: #29abe2;
        cursor: pointer;
        line-height: 16px;
        float: right;
        
        &.hidden {
            display: none;
        }
    }
}
